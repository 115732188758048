import React, {Component} from 'react';
import { Trans } from 'react-i18next';

import TypeWriter from "./TypeWriter";
import ProjectDetails from "./ProjectDetails";

import ResumeFile from "../data/test.pdf";


interface IState {
    open: boolean;
}

interface IProps {
    lang: string;
    onChangeLanguage: () => void;
    projectData: ProjectData[];
}

class Home extends Component<IProps, IState> {
        
    constructor(props: IProps) {
        super(props);
        this.state = {
            open: false
        }
    }

    componentDidMount() {
        const w: any = window;

        if (typeof w['initialize'] !== 'undefined') {
            w['initialize']();
            w['forceActive']();
        }
    }
    
    resumePath(): string {
        return "/resume/CV_LeoANTOINE_UnityDeveloper_2023_" + (this.props.lang !== undefined ? this.props.lang.toUpperCase() : "EN") + ".pdf";
    }
    
    render() {
        return (
            <div id="wrapper">
                <section id="intro" className="wrapper style1 fullscreen fade-up">
                    <div className="inner">
                        <h1>Hi, I'm Léo Antoine</h1>
                        <h2><Trans i18nKey="intro.role">Unity Developer</Trans> • <span className="tooltip"><span className="fa fa-spinner fa-spin spinner"/><span className="tooltip-text"><Trans i18nKey="intro.available">Available</Trans></span></span></h2>
                        <p className="clear-margin">
                            <Trans i18nKey="intro.desc">
                                Versatile developer specialized in Unity. My key skills are gameplay programming, game design and project management.
                            </Trans>
                        </p>
                        <br/>
                        <p><TypeWriter wait={2000} words={this.props.lang === 'fr' ?
                            [ "Crée des jeux avec ❤️", "Ouvert au télétravail 🌍", "Joueur passionné 🎮", "Min-maxer 🧪", "Fan de pizza 🍕"] :
                            [ "Make games with ❤️", "Open to remote work 🌍", "Passionate gamer 🎮", "Min-maxer 🧪", "Pizza fan 🍕" ] } />
                        </p>
                        <ul className="icons links">
                            <li><span className="icon solid fa-envelope fixed-width" /><a href="mailto:contact@leo-antoine.com">contact@leo-antoine.com</a></li>
                            <li><span className="icon brands fa-discord fixed-width" /><a href="https://discordapp.com/users/111239299046928384" target="_blank" rel="noreferrer">Slymp#4051</a></li>
                            <li><span className="icon solid fa-map-marker-alt fixed-width" /><a href="https://www.google.com/maps/place/Paris/" target="_blank" rel="noreferrer">Paris, France</a></li>
                        </ul>
                        <ul className="actions fit">
                            <li><a onClick={this.props.onChangeLanguage} className="button icon fa-flag no-select"><Trans i18nKey="intro.otherLanguage">Version française</Trans></a></li>
                            <li><a href={this.resumePath()} target="_blank" rel="noreferrer" className="button primary icon solid fa-download no-select"> <Trans i18nKey="intro.resume">Resume</Trans></a></li>
                            <li><a href="https://slymp.itch.io/" target="_blank" rel="noreferrer" className="button icon brands fa-itch-io no-select">Itch.io</a></li>
                            <li><a href="https://github.com/Slympp/" target="_blank" rel="noreferrer" className="button icon brands fa-github no-select">GitHub</a></li>
                        </ul>
                    </div>
                </section>

                <section id="about" className="wrapper style1-alt fade-up">
                    <div className="inner">
                        <ul className="alt">
                            <li>
                                <h2><span className="icon solid fa-briefcase"/> <Trans i18nKey="about.xp.title">Experience</Trans></h2>

                                <h3><Trans i18nKey="about.xp.since">Since</Trans> 2019 • Game Jams (10+)</h3>
                                <p><Trans i18nKey="about.xp.jams.role">Unity Developer, Game Designer, Project Leader</Trans></p>
                                <ul className="alt2">
                                    <li><Trans i18nKey="about.xp.jams.1">Developed gameplay mechanics and user interfaces, integrated assets ;</Trans></li>
                                    <li><Trans i18nKey="about.xp.jams.2">Designed gameplay loops / 3C, balanced various systems ;</Trans></li>
                                    <li><Trans i18nKey="about.xp.jams.3">Managed teams composed of international artists and composers.</Trans></li>
                                </ul>
                                
                                <div className="row">
                                    <div className="col-6 col-12-small"><h3>2018 <Trans i18nKey="about.xp.to">to</Trans> 2019 • Poisson Soluble</h3></div>
                                    <div className="col-6 col-12-small text-left" style={{textAlign: "right"}}><h3>Montpellier, France</h3></div>
                                </div>
                                <p><Trans i18nKey="about.xp.poisson.role">Web & Mobile Developer</Trans></p>
                                <ul className="alt2">
                                    <li><Trans i18nKey="about.xp.poisson.1">Evolved an on-demand hosting platform for Minecraft servers, using Angular ;</Trans></li>
                                    <li><Trans i18nKey="about.xp.poisson.2">Released a mobile app developed in React Native on the main stores (Google Play, App Store) ;</Trans></li>
                                    <li><Trans i18nKey="about.xp.poisson.3">Developed a Discord bot in JavaScript, communicating with a web service.</Trans></li>
                                </ul>

                                <div className="row">
                                    <div className="col-6 col-12-small"><h3>2015 <Trans i18nKey="about.xp.to">to</Trans> 2016 • Epiweb</h3></div>
                                    <div className="col-6 col-12-small text-left" style={{textAlign: "right"}}><h3>Lyon, France</h3></div>
                                </div>
                                <p><Trans i18nKey="about.xp.epiweb.role">Vice-President, Developer</Trans></p>
                                <ul className="alt2">
                                    <li><Trans i18nKey="about.xp.epiweb.1">Managed a student organisation developing websites for professionals ;</Trans></li>
                                    <li><Trans i18nKey="about.xp.epiweb.2">Designed and implemented websites meeting the needs determined with customers.</Trans></li>
                                </ul>
                            </li>
                            
                            <li>
                                <h2 style={{marginTop: "40px"}}><span className="icon solid fa-graduation-cap"/> <Trans i18nKey="about.edu.title">Education</Trans></h2>
                                <h3>2020 • <Trans i18nKey="about.edu.epitech.degree">Expert in Information Technologies</Trans></h3>
                                <p>Epitech (Lyon, France)</p>
                                <h3>2018 • <Trans i18nKey="about.edu.bjtu.degree">Master 1 in Software Engineering</Trans></h3>
                                <p><Trans i18nKey="about.edu.bjtu.location">Beijing Jiao Tong University (Beijing, China)</Trans></p>
                            </li>
                        </ul>
                    </div>
                </section>

                <section id="skills" className="wrapper style2 fade-up">
                    <div className="inner">
                        <h2><Trans i18nKey="nav.skills">Skills</Trans></h2>
                        <div className="features">
                            <section>
                                <span className="icon solid major brands fa-unity"/>
                                <h3>Unity 3D</h3>
                                <ul>
                                    <li><Trans i18nKey="skills.unity.1">Gameplay</Trans></li>
                                    <li><Trans i18nKey="skills.unity.2">AI, Procedural generation</Trans></li>
                                    <li><Trans i18nKey="skills.unity.3">Networking : Mirror, MLAPI, SpatialOS</Trans></li>
                                    <li><Trans i18nKey="skills.unity.4">UI : UI Toolkit, uGUI, IMGUI</Trans></li>
                                    <li><Trans i18nKey="skills.unity.5">Optimization, Debugging</Trans></li>
                                    <li>Data-Oriented Technology Stack</li>
                                </ul>
                            </section>
                            <section>
                                <span className="icon solid major fa-code"/>
                                <h3><Trans i18nKey="skills.dev">Software & Web Development</Trans></h3>
                                <ul>
                                    <li>C#</li>
                                    <li>C, C++, Java</li>
                                    <li>JavaScript, PHP</li>
                                    <li>Angular, React, Bootstrap</li>
                                    <li>SQL, NoSQL, GraphQL</li>
                                    <li>Python, Bash</li>
                                </ul>
                            </section>
                            <section>
                                <span className="icon solid major fa-flask"/>
                                <h3>Game Design</h3>
                                <ul>
                                    <li><Trans i18nKey="skills.gameDesign.1">Systems</Trans></li>
                                    <li><Trans i18nKey="skills.gameDesign.2">Balance</Trans></li>
                                    <li><Trans i18nKey="skills.gameDesign.3">Content</Trans></li>
                                    <li><Trans i18nKey="skills.gameDesign.4">Economy</Trans></li>
                                    <li><Trans i18nKey="skills.gameDesign.5">UI / UX</Trans></li>
                                </ul>
                            </section>
                            <section>
                                <span className="icon solid major fa-user"/>
                                <h3><Trans i18nKey="skills.soft.title">Soft skills</Trans></h3>
                                <ul>
                                    <li><Trans i18nKey="skills.soft.1">Solution-oriented</Trans></li>
                                    <li><Trans i18nKey="skills.soft.2">Proactive</Trans></li>
                                    <li><Trans i18nKey="skills.soft.3">Teamwork</Trans></li>
                                    <li><Trans i18nKey="skills.soft.4">Flexibility</Trans></li>
                                    <li><Trans i18nKey="skills.soft.5">Leadership</Trans></li>
                                </ul>
                            </section>
                            <section>
                                <span className="icon solid major fa-tools"/>
                                <h3><Trans i18nKey="skills.tools.title">Tools</Trans></h3>
                                <ul>
                                    <li><Trans i18nKey="skills.tools.1">Universal Rendering Pipeline</Trans></li>
                                    <li><Trans i18nKey="skills.tools.2">Version control</Trans></li>
                                    <li><Trans i18nKey="skills.tools.3">Linux environment</Trans></li>
                                    <li><Trans i18nKey="skills.tools.4">Trello, Google Suite, Miro</Trans></li>
                                    <li><Trans i18nKey="skills.tools.5">OBS, DaVinci Resolve</Trans></li>
                                </ul>
                            </section>
                            <section>
                                <span className="icon solid major fa-tasks"/>
                                <h3><Trans i18nKey="skills.projectManagement.title">Project Management</Trans></h3>
                                <ul>
                                    <li><Trans i18nKey="skills.projectManagement.1">Agile methodologies</Trans></li>
                                    <li><Trans i18nKey="skills.projectManagement.2">Architecture</Trans></li>
                                    <li><Trans i18nKey="skills.projectManagement.3">Scheduling</Trans></li>
                                    <li><Trans i18nKey="skills.projectManagement.4">Quality assurance</Trans></li>
                                    <li><Trans i18nKey="skills.projectManagement.5">Technical documentation</Trans></li>
                                </ul>
                            </section>
                        </div>
                    </div>
                </section>

                <section id="projects" className="wrapper style2-alt spotlights">
                    {this.props.projectData.map((data, i) => 
                        <ProjectDetails key={data.key}  data={data} />
                    )}
                </section>
            </div>
        )
    }
}
export default Home;