import React, { Component } from 'react';

type TypeWriterState = {
    txt: string
}

class TypeWriter extends Component<{ words: string[], wait: number }, TypeWriterState> {
    private wordIndex: number = 0;
    private isDeleting: boolean = false;
    private regExp: RegExp = new RegExp(/^[A-Za-z ]+$/);
    private timeout: any;
    
    constructor(props: any) {
        super(props);
        
        this.state = { txt: "" };
        this.type();
    }
    
    type() {
        const current: number = this.wordIndex % this.props.words.length;
        const fullTxt: string = this.props.words[current];

        let length = this.state.txt.length;
        if (this.isDeleting) {
            do {
                length--;
            } while (length > 0 && !this.regExp.test(fullTxt[length]));
        } else {
            do {
                length++;
            } while (length < fullTxt.length && !this.regExp.test(fullTxt[length]))
        }
        this.setState({ txt: fullTxt.substring(0, length) })


        let typeSpeed = 50;

        if (this.isDeleting) {
            typeSpeed /= 2;
        }

        if (!this.isDeleting && this.state.txt === fullTxt) {
            typeSpeed = this.props.wait;
            this.isDeleting = true;
        } else if (this.isDeleting && this.state.txt === "") {
            this.isDeleting = false;
            this.wordIndex++;
            typeSpeed = 1000;
        }

        this.timeout = setTimeout(() => this.type(), typeSpeed);
    }
    
    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    render() {
        return (
            <span>{this.state.txt}<span className="cursor" /></span>
        );
    }
}

export default TypeWriter;