import React, { Component } from 'react';
import {Trans} from "react-i18next";

class Sidebar extends Component {
    render() {
        return (
            <section id="sidebar">
                <div className="inner">
                    <nav>
                        <ul>
                            <li><a href="#intro"><Trans i18nKey="nav.welcome">Welcome</Trans></a></li>
                            <li><a href="#about"><Trans i18nKey="nav.about">About</Trans></a></li>
                            <li><a href="#skills"><Trans i18nKey="nav.skills">Skills</Trans></a></li>
                            <li><a href="#projects"><Trans i18nKey="nav.projects">Projects</Trans></a></li>
                        </ul>
                    </nav>
                </div>
            </section>
        );
    }
}
export default Sidebar;