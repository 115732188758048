import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Trans} from "react-i18next";

interface IProps {
    projectData: ProjectData[];
    active?: string;
}

class Header extends Component<IProps> {
    constructor(props: IProps) {
        super(props);
    }
    
    render() {
        return (
            <header id="header">
                <Link to="/" className="title"><span className="icon solid fa-home" /> <Trans i18nKey="home">Home</Trans></Link>
                <nav>
                    <ul>
                        <li key='projects'><Link to="/projects" className={typeof this.props.active === 'undefined' ? 'active' : ''}><Trans i18nKey="nav.projects">Projects</Trans></Link></li>
                        {this.props.projectData.map((d: ProjectData, i: number) => 
                            <li key={d.key}>
                                <Link to={`/projects/${d.key}`} className={d.key === this.props.active ? 'active' : ''}>{d.title}</Link>
                            </li>
                        )}
                    </ul>
                </nav>
            </header>
        );
    }
}
export default Header;