import React, {Component} from "react";
import Unity, {UnityContent} from "react-unity-webgl";
import {Trans} from "react-i18next";

interface IProps {
    projectKey: string;
    sources?: string;
    itch?: string;
}

interface IState {
    buildPath: string;
    loaded: boolean;
    error: boolean;
}

class UnityContainer extends Component<IProps, IState> {
    private readonly unityContent: UnityContent;
    
    constructor(props: IProps) {
        super(props);
        
        this.state = { buildPath: "/builds/" + this.props.projectKey + "/", loaded: false, error: false };
        
        this.unityContent = new UnityContent(
            this.state.buildPath + "Build.json",
            this.state.buildPath + "UnityLoader.js",
            {
                modules: {
                    onAbort: () => {},
                },
            }
        );
        
        this.unityContent.on("loaded", () => {
            this.setState({ loaded: true });
        });

        this.unityContent.on("error", (message:string) => {
            this.setState({ error: true });
            console.log("UnityContext failed to load: " + message);
        });
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize);

        this.resize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }
    
    getZipName(): string {
        return this.props.projectKey.split('-').map(word => word.charAt(0).toUpperCase() + word.substring(1) ).join('') + ".zip";
    } 

    resize = () => {
        const unityElement = document.getElementById("unity") as HTMLInputElement;
        const width = parseFloat(window.getComputedStyle(unityElement).width);

        unityElement.setAttribute("style", "height: " + (width / 16 * 9) + "px !important");
    }

    toggleFullScreen = () => {
        this.unityContent.setFullscreen(true);
    }
    
    render() {
        return (
            <div>
                <div className="unity-container" id="unity">
                    {!this.state.loaded && 
                        <div className="unity-message">
                            {this.state.error ? 
                                <div className="unity-error">
                                    <div style={{marginBottom: "20px", color: "orangered" }}><span className="fa fa-exclamation-circle" /> 
                                        <Trans i18nKey="webGL-error">Something wrong happened with the WebGL player</Trans>...
                                    </div>
                                    <a href={this.state.buildPath + this.getZipName()} target="_blank" rel="noreferrer" className="button icon brands fa-windows no-select" download>Windows Build</a>
                                </div> 
                                : <div className="unity-loader"><span className="fa fa-spinner fa-pulse" style={{color: "#fff"}}/></div>
                            }
                        </div> 
                    }
                    <Unity unityContent={this.unityContent} className="unity-content"/>
                </div>
                <ul className="actions project-buttons">
                    <li>
                        <button id="fullScreenButton" className="button primary icon solid fa-expand no-select" onClick={this.toggleFullScreen}>
                            <Trans i18nKey="projects.fullscreen">Fullscreen</Trans>
                        </button>
                    </li>
                    {this.props.itch !== undefined && this.props.itch.length > 0 &&
                        <li>
                            <a href={this.props.itch} target="_blank" rel="noreferrer" className="button icon brands fa-itch-io no-select">Itch.io</a>
                        </li>
                    }
                    {this.props.sources !== undefined && this.props.sources.length > 0 &&
                        <li>
                            <a href={this.props.sources} target="_blank" rel="noreferrer" className="button icon brands fa-github no-select">GitHub</a>
                        </li>
                    }
                </ul>
            </div>
        );
    }
}
export default UnityContainer;