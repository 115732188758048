import React from "react";
import {
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    Redirect
} from "react-router-dom";
import {Trans, Translation} from 'react-i18next';
import DOMPurify from 'dompurify';
import Header from "./Header";
import UnityContainer from "./UnityContainer";
import {MutedPlayer} from "react-muted-video-player";

interface IProps {
    projectData: ProjectData[];
}

function Projects(props: IProps) {
    let match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}/:projectKey`}>
                <Project projectData={props.projectData} />
            </Route>
            <Route path={match.path}>
                <ProjectHome projectData={props.projectData} />
            </Route>
        </Switch>
    );
}

type TParams =  { projectKey: string };

function Project(props: IProps) {
    const { projectKey } = useParams<TParams>();
    const data: ProjectData = props.projectData.filter((d: ProjectData) => d.key === projectKey)[0];
    
    if (typeof data === 'undefined')
        return <Redirect to="/projects"/>
    
    const parse = require('html-react-parser');
  
    return (
        <div>
            <Header projectData={props.projectData} active={projectKey}/>
            <div id="wrapper">
                <section id="main" className="wrapper">
                    <div className="inner">
                        <h1 className="major">{data.title}</h1>
                        <div className="row gtr-uniform">
                            <div className="col-9 col-12-xsmall">
                                <UnityContainer projectKey={data.key} key={data.key} sources={data.sources} itch={data.itch} />
                                
                                {data.tags.length > 0 &&
                                    <ul className="actions no-select fixed tag-wrapper" style={{marginBlockEnd: "0.5em"}}>
                                        {data.tags.map((tag) => <li key={tag} className="tag-li"><span className="tag">{tag}</span></li>)}
                                    </ul>
                                }
                                
                                <Translation>
                                    {
                                        (t, { i18n }) => <div>{parse(DOMPurify.sanitize(t(`projects.${data.key}.long`)))}</div>
                                    }
                                </Translation>
                                
                                {data.credits !== undefined && data.credits.length > 0 && 
                                    <p>
                                        <h3><Trans i18nKey="projects.credits">Credits</Trans></h3>
                                        {parse(DOMPurify.sanitize(data.credits))}
                                    </p>
                                }
                            </div>
                            <div className="col-3 col-12-xsmall">
                                <div className="box alt">
                                    <div className="row gtr-uniform">
                                        {data.imgs.map((img, i) =>
                                            <div className="col-12" key={img}><span className="image fit"><img src={"/images/" + img} alt={data.title}/></span></div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

function ProjectHome(props: IProps) {
    return (
        <div>
            <Header projectData={props.projectData} />
            <section id="main" className="wrapper projects-home">
                <div className="inner">
                    <div className="box alt">
                        <div className="row gtr-uniform">
                            {props.projectData.map((data, i) => 
                                (<div className="col-4" key={data.key}>
                                    <span className="image fit" >
                                        <Link to={"/projects/" + data.key} style={{borderBottom: 0}}>
                                            {data.video != undefined ?
                                                <MutedPlayer loop={true} autoPlay={true} muted={true} playsInline={true}>
                                                    <source src={"images/" + data.video} type={"video/mp4"} />
                                                    <img src={"images/" + data.fallbackImg} alt={"Video is not supported by your browser: " + data.title + " fallback image"} />
                                                </MutedPlayer>
                                                :
                                                <img src={"images/" + data.fallbackImg} alt={data.title} />
                                            }
                                        </Link>
                                        <h3 className="projects-home-title">{data.title}</h3>
                                    </span>
                                </div>)
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Projects;