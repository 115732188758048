import React, { Component } from 'react';
import {Trans} from "react-i18next";

class Footer extends Component {
    render() {
        return (
            <footer id="footer" className="wrapper style1-alt">
                <div className="inner" style={{textAlign: "center"}}>
                    <ul className="icons">
                        <li><a href="https://slymp.itch.io/" target="_blank" rel="noreferrer" className="icon brands fa-itch-io"><span
                            className="label">Itch.io</span></a></li>
                        <li><a href="https://github.com/Slympp/" target="_blank" rel="noreferrer" className="icon brands fa-github"><span
                            className="label">GitHub</span></a></li>
                        <li><a href="https://twitter.com/Slymp_" target="_blank" rel="noreferrer" className="icon brands fa-twitter"><span
                            className="label">Twitter</span></a></li>
                    </ul>
                    <ul className="menu">
                        <li>&copy; Léo Antoine. <Trans i18nKey="copyright">All rights reserved</Trans>.</li>
                        {/*<li>Design: <a href="http://html5up.net">HTML5 UP</a></li>*/}
                    </ul>
                </div>
            </footer>
        )
    }
}
export default Footer;