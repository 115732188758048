import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Sidebar from "./components/Sidebar";
import Home from "./components/Home";
import Footer from "./components/Footer";
import Projects from "./components/Projects";

import projectData from "./data/projects.json";

import ScrollToTop from "./components/ScrollToTop";


function App() {
    const { t, i18n } = useTranslation();
    const changeLanguage = () => i18n.changeLanguage(i18n.language === 'fr' ? 'en' : 'fr');
    
    return (
        <Router>
            <ScrollToTop/>
            <Switch>
                <Route path="/" exact>
                    <Sidebar/>
                    <Home lang={i18n.language} onChangeLanguage={() => changeLanguage()} projectData={projectData} />
                </Route>
                <Route path="/projects">
                    <Projects projectData={projectData} />
                </Route>
                <Route path="*">
                    <Redirect to="/"/>
                </Route>
                </Switch>
            <Footer/>
        </Router>
    );
}

export default App;