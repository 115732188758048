import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Trans, Translation} from 'react-i18next';
import DOMPurify from "dompurify";
import {MutedPlayer} from "react-muted-video-player";

interface IProps {
    data: ProjectData;
}

class ProjectDetails extends Component<IProps> {
    render() {
        const parse = require('html-react-parser');
        
        return (
            <section>
                <Link to={"/projects/" + this.props.data.key} className="image">
                    <MutedPlayer loop={true} autoPlay={true} muted={true} playsInline={true}>
                        <source src={"images/" + this.props.data.video} type={"video/mp4"} />
                        <img src={"images/" + this.props.data.fallbackImg} alt={"Video is not supported by your browser: " + this.props.data.title + " fallback image"} data-position="center center"/>
                    </MutedPlayer>
                </Link>
                <div className="content">
                    <div className="inner">
                        <h2 style={{marginBottom: 0}}>{this.props.data.title}</h2>
                        {this.props.data.tags.length > 0 &&
                        <ul className="actions no-select fixed tag-wrapper">
                            {this.props.data.tags.map((tag) => <li key={tag} className="tag-li"><span className="tag">{tag}</span></li>)}
                        </ul>
                        }
                        <Translation>
                            {
                                (t, { i18n }) => <p>{parse(DOMPurify.sanitize(t(`projects.${this.props.data.key}.short`)))}</p>
                            }
                        </Translation>
                        <ul className="actions">
                            <li><Link to={"/projects/" + this.props.data.key} className="button icon solid fa-gamepad"><Trans i18nKey="play">Play</Trans></Link></li>
                        </ul>
                    </div>
                </div>
            </section>
        );
    }
}
export default ProjectDetails;